@import "~@/assets/scss/variables.scss";

















































































































































































fieldset.form-col {
  border: thin solid rgb(233, 236, 239);
  border-radius: 5px;
}

fieldset.form-col legend {
  font-size: 1em;
  padding-right: 1px;
  padding-left: 1px;
  width: auto;
  margin: 0;
  color: rgb(0, 0, 0);
}

.round-button {
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.mobile-button {
  padding: 0;
  width: 100%;
  height: 24px;
  border-radius: 0.25rem;
}

::v-deep .invalid-row {
  color: #aaa;
  background-color: #fff !important;
}

::v-deep .invalid-row .round-button {
  background-color: #aaa;
  border-color: #aaa;
}

::v-deep .invalid-row .mobile-button {
  background-color: #aaa;
  border-color: #aaa;
}
